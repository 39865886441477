import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './index.css';
import { Acidente } from './types';
import './index.css';

interface Props {
  dados: Acidente[];
}

export function Tabela({ dados }: Props) {
  const [paginaAtual, setPaginaAtual] = useState(1); 
  const registrosPorPagina = 10;
  const dadosOrdenados = dados.slice().sort((a, b) => {
    return new Date(b.data_ocorrido).getTime() - new Date(a.data_ocorrido).getTime();
  });

  const totalPaginas = Math.ceil(dados.length / registrosPorPagina);

  const indiceUltimoRegistro = paginaAtual * registrosPorPagina;
  const indicePrimeiroRegistro = indiceUltimoRegistro - registrosPorPagina;
  const dadosPaginados = dadosOrdenados.slice(indicePrimeiroRegistro, indiceUltimoRegistro);

  const detalhesAcidente = (id: string) => {
    localStorage.setItem('acidenteId', id);
  };

  const irParaPrimeiraPagina = () => {
    setPaginaAtual(1);
  };

  const irParaUltimaPagina = () => {
    setPaginaAtual(totalPaginas);
  };

  const formatarData = (data: string) => {
    const dataUTC = new Date(data);
    return dataUTC.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };
  const formatarHora = (hora: string) => {
    const horaUTC = new Date(hora);
    return horaUTC.toLocaleTimeString('pt-BR', {
      timeZone: 'UTC',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>Lista de Acidentes</div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <Table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th>Agente</th>
                <th>Logradouro 1</th>
                <th>Logradouro 2</th>
                <th>Bairro</th>
                <th>UF</th>
                <th>Data</th>
                <th>Hora</th>
                <th>Tipo de Acidente</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {dadosPaginados.map((acidente, index) => (
                <tr key={index} className='table-row' style={{ cursor: 'pointer' }}>
                  <td>{acidente.agente_cadastro.nome}</td>
                  <td>{acidente.rua1}</td>
                  <td>{acidente.rua2}</td>
                  <td>{acidente.bairro}</td>
                  <td>{acidente.uf}</td>
                  <td>{formatarData(acidente.data_ocorrido)}</td>
                  <td>{formatarHora(acidente.hora)}</td>
                  <td>{acidente.tipo_acidente}</td>
                  <td>
                    <Link
                      to='/formulario'
                      className='btn btn-sm btn-primary'
                      onClick={() => detalhesAcidente(acidente.id)}
                    >
                      <FontAwesomeIcon icon={faSearch} className='me-1' /> Detalhes
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <div>Total de Páginas: {totalPaginas}</div>
            <div>Total Registros: {dados.length}</div>
          </div>
          <div>
            <Button
              onClick={irParaPrimeiraPagina}
              disabled={paginaAtual === 1}
              className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Button>
            <Button
              onClick={() => setPaginaAtual(paginaAtual - 1)}
              disabled={paginaAtual === 1}
              className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
              onClick={() => setPaginaAtual(paginaAtual + 1)}
              disabled={paginaAtual === totalPaginas}
              className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
            <Button
              onClick={irParaUltimaPagina}
              disabled={paginaAtual === totalPaginas}
              className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Button>
          </div>
          <div>Página: {paginaAtual}</div>
        </div>
      </div>
    </div>
  );
}